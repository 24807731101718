import React, {FC} from 'react';

const NotFoundPage: FC = () => {
  return (
    <p>Page Not Found</p>
  );

}

export default NotFoundPage;
